import { Dialog, DialogContent, Typography, Button, Box, styled } from '@mui/material';
import { DialogCloseBtn } from '@/components/UIKit/Modal/misc';

type Props = {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
};

export const PostVisitPopup = (props: Props) => {
  const { open, onClose } = props;

  return (
    <PostVisitDialog open={open} onClose={onClose} onTransitionExited={props.onClose}>
      <DialogContent sx={{ pt: '40px', pb: '16px', maxWidth: '700px', position: 'relative' }}>
        <DialogCloseBtn onClick={onClose} />

        <Box textAlign="center" pt={2}>
          <Typography 
            variant="h3" 
            mb="12px"
            textAlign="left"
            sx={{
              color: '#171c17'
            }}
          >
            Post-Visit Summaries<br/>
            on Aesthetic Record
          </Typography>
          
          <Typography 
            variant="body1Light" 
            component="div" 
            mb="16px"
            textAlign="left"
          >
            As a Provider using Aesthetic Record, you will now be redirected to enter your 
            post-visit summary there. JOYA will sync with the visit information you add on 
            Aesthetic Record so you can continue to see all of your assigned patient 
            information in one place.
          </Typography>

          <Box 
            mt="auto" 
            pt={2} 
            borderTop="1px solid #F1F0EE"
            display="flex" 
            gap={2} 
            justifyContent="space-between"
          >
            <Button 
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onContinue}
            >
              Go to Aesthetic Record
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </PostVisitDialog>
  );
};

const PostVisitDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 700px;
    width: 100%;
  }
`;