import { TrashIcon } from '@/components/UIKit/Icons';
import { Select } from '@/components/UIKit/Inputs/Select/Select';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { DialogCloseBtn } from '@/components/UIKit/Modal/misc';
import useOpenable from '@/hooks/useOpenable';
import { selectMenuPropClasses } from '@/themes/themeOptions/components/inputs';
import { Assistant } from '@/types/apiContract/assistant';
import { yupEmail } from '@/utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Badge as BaseBadge } from '@mui/base';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ROLE_OPTIONS } from './constants';
import { ChangeRole } from './types';
import { getAssistantsUpdateInfo } from './utils';

type Props = {
  loggedInProvider: { email: string };
  assistants: Assistant[];
  isLoadingAssistants?: boolean;
  onClose: () => void;
  onAddContact: (values: FormInputs) => Promise<{ isSuccess: boolean; fieldErrMessage?: string }>;
  onSave: (params: {
    toDeleteAssistants: string[];
    toChangeRolesAssistants: ChangeRole[];
  }) => Promise<void>;
};

type FormInputs = {
  email: string;
};

export const ManageAccessModalView = (props: Props) => {
  const [toAddAssistants, setToAddAssistants] = useState<FormInputs[]>([]);
  const [toDeleteAssistants, setToDeleteAssistants] = useState<string[]>([]);
  const [toChangeRolesAssistants, setToChangeRolesAssistants] = useState<ChangeRole[]>([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const isLoading = isSaveLoading;

  const { isOpen, onClose } = useOpenable({
    shouldStartOpen: true,
    isOnCloseDisabled: isLoading,
  });

  const { control, register, handleSubmit, setError, reset } = useManageAccessForm({
    reValidateMode: 'onSubmit',
  });

  const onAddContactSubmit = (values: FormInputs) => {
    const newEmail = values.email.toLowerCase();

    const alreadyInToAdd = toAddAssistants.some((asst) => asst.email.toLowerCase() === newEmail);

    const alreadyAnAssistant = props.assistants.some(
      (asst) => asst.email.toLowerCase() === newEmail,
    );

    if (alreadyInToAdd || alreadyAnAssistant) {
      setError('email', {
        message: 'This email is already added or invited.',
      });
      return;
    }

    setToAddAssistants((curr) => [...curr, values]);
    reset();
  };

  const onDelete = (assistantId: string) => {
    setToDeleteAssistants((curr) => [...curr, assistantId]);
    setToChangeRolesAssistants((curr) => curr.filter((as) => as.assistantId !== assistantId));
  };

  const onRoleChange = (
    assistantId: string,
    roleFrom: Assistant['role'],
    roleTo: Assistant['role'],
  ) => {
    setToChangeRolesAssistants((curr) => {
      const newArr = [...curr];
      const existingAssistantIndex = newArr.findIndex(
        (assistantObj) => assistantObj.assistantId === assistantId,
      );

      // is new update
      if (existingAssistantIndex === -1) {
        const newAssistant: ChangeRole = {
          assistantId,
          roleFrom,
          roleTo,
        };

        newArr.push(newAssistant);
        return newArr;
      }

      // changed it back to original state
      const hasChangedItBackToOriginal = roleTo === newArr[existingAssistantIndex].roleFrom;
      if (hasChangedItBackToOriginal) {
        newArr.splice(existingAssistantIndex, 1);
        return newArr;
      }

      // is updating same one again
      newArr[existingAssistantIndex].roleTo = roleTo;
      return newArr;
    });
  };

  const onSave = async () => {
    setIsSaveLoading(true);
    try {
      await Promise.all(
        toAddAssistants.map(async (assistant) => {
          const { isSuccess, fieldErrMessage } = await props.onAddContact({
            email: assistant.email,
          });
          if (!isSuccess && fieldErrMessage) {
            setError('email', { message: fieldErrMessage });
            throw new Error(fieldErrMessage);
          }
        }),
      );

      await props.onSave({
        toDeleteAssistants,
        toChangeRolesAssistants,
      });

      setToAddAssistants([]);
      setToDeleteAssistants([]);
      setToChangeRolesAssistants([]);
      onClose();
    } finally {
      setIsSaveLoading(false);
    }
  };

  const nonDeletedAssistants = props.assistants.filter(
    (assistant) => !toDeleteAssistants.includes(assistant.id),
  );

  const displayedAssistants = [
    ...nonDeletedAssistants.map((assistant) => {
      const assistantModification = toChangeRolesAssistants.find(
        (as) => as.assistantId === assistant.id,
      );
      if (assistantModification) {
        return {
          ...assistant,
          role: assistantModification.roleTo,
        } satisfies Assistant;
      }

      return { ...assistant } satisfies Assistant;
    }),

    ...toAddAssistants.map((assistant, index) => ({
      id: `pending-${index}`,
      email: assistant.email,
      role: 'USER',
      status: 'PENDING_INVITE',
    })),
  ];

  const hasZeroAssistants = !props.isLoadingAssistants && displayedAssistants.length === 0;

  const { adminCount: adminCountAfterUpdate } = getAssistantsUpdateInfo({
    assistants: props.assistants,
    toChangeRolesAssistants,
    toDeleteAssistants,
  });
  const hasExactlyOneAdmin = adminCountAfterUpdate === 1;

  const hasChanges =
    toAddAssistants.length > 0 ||
    toChangeRolesAssistants.length > 0 ||
    toDeleteAssistants.length > 0;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onTransitionExited={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '700px',
          width: '100%',
        },
      }}
    >
      <DialogContent>
        <DialogCloseBtn onClick={onClose} disabled={isLoading} />
        <Typography variant="h3" mb="12px">
          Manage Access
        </Typography>
        <Typography display="block" variant="body3Emphasized" mb="24px" color="text.secondary">
          Share access with your team and they will receive an email to log in.
        </Typography>
        <Box>
          <form onSubmit={handleSubmit(onAddContactSubmit)}>
            <Box display="flex" alignItems="start" justifyContent="space-between">
              <TextField
                control={control}
                register={register('email')}
                label="Email"
                placeholder="Enter Email"
                sx={{ flex: 1 }}
                fullWidth
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ marginLeft: '12px', mt: '35px', width: '135px' }}
              >
                Invite
              </LoadingButton>
            </Box>
          </form>
        </Box>
        <Box
          sx={{
            flex: 1,
            mt: '32px',
            ...(hasZeroAssistants && { display: 'none' }),
          }}
        >
          {props.isLoadingAssistants ? (
            <LoadingSkeleton />
          ) : (
            <Box>
              <Typography display="inline-block" variant="body3Emphasized" color="text.secondary">
                MODIFY ACCESS
              </Typography>
              {displayedAssistants.map((assistant) => {
                const isProtectedAdmin = hasExactlyOneAdmin && assistant.role === 'ADMIN';

                // disable role select logic
                let selectRoleDisabledReason = '';
                if (isProtectedAdmin) {
                  selectRoleDisabledReason =
                    'You cannot change the role, as this user is the only Admin';
                }
                const isSelectRoleDisabled = !!selectRoleDisabledReason;

                // disable delete button logic
                const isSelf = assistant.email === props.loggedInProvider.email;
                const isDeleteDisabled = isSelf || isProtectedAdmin;

                let currentRole = assistant.role;
                const pendingRoleChange = toChangeRolesAssistants.find(
                  (rc) => rc.assistantId === assistant.id,
                );
                if (pendingRoleChange) {
                  currentRole = pendingRoleChange.roleTo;
                }

                return (
                  <AssistantBox key={assistant.id} data-testid={`assistant-row-${assistant.email}`}>
                    <Box display="flex" alignItems="center" gap="8px">
                      <Typography variant="body2">{assistant.email}</Typography>
                      {(assistant.status === 'INVITED' ||
                        assistant.status === 'PENDING_INVITE') && (
                        <Badge>
                          <Typography variant="body3Emphasized">Pending</Typography>
                        </Badge>
                      )}
                    </Box>
                    <AssistantOptions>
                      <Tooltip
                        title={selectRoleDisabledReason}
                        placement="top"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: '200px',
                              textAlign: 'center',
                              left: '-35px',
                              bottom: '-10px',
                            },
                          },
                        }}
                      >
                        <Box display={'flex'}>
                          <SelectRole
                            disabled={isSelectRoleDisabled || isLoading}
                            value={currentRole}
                            variant="standard"
                            MenuProps={{
                              classes: selectMenuPropClasses,
                              PaperProps: {
                                sx: {
                                  width: '109px',
                                },
                              },
                            }}
                            options={ROLE_OPTIONS}
                            defaultValue={assistant.role}
                            onChange={(e) =>
                              onRoleChange(
                                assistant.id,
                                assistant.role as Assistant['role'],
                                String(e.target.value) as Assistant['role'],
                              )
                            }
                          />
                        </Box>
                      </Tooltip>
                      <IconButton
                        onClick={() => onDelete(assistant.id)}
                        disabled={isDeleteDisabled || isLoading}
                        aria-label="delete"
                      >
                        <TrashIcon />
                      </IconButton>
                    </AssistantOptions>
                  </AssistantBox>
                );
              })}
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          ...(displayedAssistants.length === 0 && { display: 'none' }),
        }}
      >
        <Button variant="text" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={onSave}
          loading={isSaveLoading}
          disabled={isSaveLoading || !hasChanges}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ManageAccessModalView.defaultProps = {
  onSubmit: async () => {},
  onClose: () => {},
};

const useManageAccessForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    email: yupEmail().required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};

const AssistantBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 12px 0px;
  border-bottom: 0.5px solid rgba(233, 235, 237, 1);

  :last-of-type {
    border-bottom: 0px;
  }
`;

const SelectRole = styled(Select)`
  display: flex;
  flex-direction: row-reverse;
  && {
    &.MuiInputBase-root::before {
      border-bottom: none;
    }
    .MuiSelect-select.MuiSelect-standard {
      display: flex;
      padding: 7px;
      padding-left: 30px;
      padding-right: 14px;
    }
  }

  &.MuiInputBase-root::after {
    border: none;
  }
  :hover {
    &.MuiInputBase-root::before {
      border-bottom: none;
    }
  }
  .MuiInput-input:focus {
    background: none;
  }
  .MuiSelect-icon {
    left: 0;
  }
`;

const AssistantOptions = styled(Box)`
  display: flex;
  min-width: 122px;
  justify-content: space-between;
`;

const Badge = styled(BaseBadge)`
  margin: 0px;
  padding: 2px 8px;
  background-color: rgba(239, 126, 52, 0.1);
  color: rgba(220, 104, 3, 1);
  border-radius: 6px;
  display: flex;
`;

const LoadingSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" height="24px" width="120px" sx={{ borderRadius: '6px' }} />
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            key={`skeleton-${i}`}
            variant="rectangular"
            height={53}
            sx={{ my: '4px', borderRadius: '8px' }}
            width="100%"
          />
        ))}
    </Box>
  );
};
